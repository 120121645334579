var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c(
                "v-card",
                { staticClass: "mb-4" },
                [
                  _c("v-card-title", [_vm._v("Trip Ticket Template")]),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          !_vm.hasTripTicket
                            ? _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("upload-file", {
                                    attrs: {
                                      recordType: "tripticket",
                                      recordId: 1,
                                      showSize: "",
                                    },
                                    on: {
                                      onUpload: function ($event) {
                                        return _vm.onUpload(
                                          "tripTicketAttachments"
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("attachments", {
                                ref: "tripTicketAttachments",
                                attrs: {
                                  recordType: "tripticket",
                                  recordId: 1,
                                },
                                on: {
                                  onFiles: _vm.handleOnTripTicket,
                                  onFileDelete: _vm.refreshStore,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card",
                [
                  _c("v-card-title", [_vm._v("Trip Ticket Keywords")]),
                  _c(
                    "v-card-text",
                    [
                      _c("small", [
                        _vm._v("Click on a row to copy the keyword"),
                      ]),
                      _c("v-simple-table", {
                        attrs: { dense: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _c("thead", [
                                  _c("tr", [
                                    _c("th", { staticClass: "text-left" }, [
                                      _vm._v("Value"),
                                    ]),
                                    _c("th", { staticClass: "text-left" }, [
                                      _vm._v("Keyword"),
                                    ]),
                                  ]),
                                ]),
                                _c(
                                  "tbody",
                                  [
                                    _vm._l(
                                      _vm.tripTicketKeywords,
                                      function (item) {
                                        return [
                                          _c(
                                            "tr",
                                            {
                                              key: item.keyword,
                                              on: {
                                                click: function ($event) {
                                                  return _vm.copyToClipboard(
                                                    item.keyword
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("td", [
                                                _vm._v(_vm._s(item.value)),
                                              ]),
                                              _c("td", [
                                                _vm._v(_vm._s(item.keyword)),
                                              ]),
                                            ]
                                          ),
                                          item.subTags
                                            ? _vm._l(
                                                item.subTags,
                                                function (subItem) {
                                                  return _c(
                                                    "tr",
                                                    {
                                                      key: `${item.keyword}-${subItem.keyword}`,
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.copyToClipboard(
                                                            subItem.keyword
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass: "pl-16",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              subItem.value
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass: "pl-16",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              subItem.keyword
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              )
                                            : _vm._e(),
                                        ]
                                      }
                                    ),
                                    Object.keys(_vm.cffKeywords).length
                                      ? _c(
                                          "p",
                                          {
                                            staticClass:
                                              "font-weight-bold mt-4 mb-2",
                                          },
                                          [_vm._v("Custom Form Fields")]
                                        )
                                      : _vm._e(),
                                    _vm._l(
                                      Object.keys(_vm.cffKeywords),
                                      function (step) {
                                        return [
                                          _c(
                                            "p",
                                            { key: step, staticClass: "mb-0" },
                                            [_vm._v(_vm._s(step))]
                                          ),
                                          _vm._l(
                                            _vm.cffKeywords[step],
                                            function (item) {
                                              return _c(
                                                "tr",
                                                {
                                                  key: item.keyword,
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.copyToClipboard(
                                                        item.keyword
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("td", [
                                                    _vm._v(_vm._s(item.value)),
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(item.keyword)
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            }
                                          ),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c(
                "v-card",
                { staticClass: "mb-4" },
                [
                  _c("v-card-title", [_vm._v("Permission Slip Template")]),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          !_vm.hasPermissionSlipEnglish
                            ? _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("upload-file", {
                                    attrs: {
                                      recordType: "permissionslip",
                                      recordId: 1,
                                      showSize: "",
                                    },
                                    on: {
                                      onUpload: function ($event) {
                                        return _vm.onUpload(
                                          "permissionSlipEnglishAttachments"
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("attachments", {
                                ref: "permissionSlipEnglishAttachments",
                                attrs: {
                                  recordType: "permissionslip",
                                  recordId: 1,
                                },
                                on: {
                                  onFiles: _vm.handleOnPermissionSlipEnglish,
                                  onFileDelete: _vm.refreshStore,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          !_vm.hasPermissionSlipSpanish
                            ? _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("upload-file", {
                                    attrs: {
                                      recordType: "permissionslip",
                                      recordId: 2,
                                      showSize: "",
                                    },
                                    on: {
                                      onUpload: function ($event) {
                                        return _vm.onUpload(
                                          "permissionSlipSpanishAttachments"
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("attachments", {
                                ref: "permissionSlipSpanishAttachments",
                                attrs: {
                                  recordType: "permissionslip",
                                  recordId: 2,
                                },
                                on: {
                                  onFiles: _vm.handleOnPermissionSlipSpanish,
                                  onFileDelete: _vm.refreshStore,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "px-2", attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-subheader", [
                                _vm._v(
                                  "Display Download Permission Slip button?"
                                ),
                              ]),
                              _c(
                                "v-radio-group",
                                {
                                  attrs: { row: "" },
                                  model: {
                                    value:
                                      _vm.templates.showDownloadPermissionSlip,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.templates,
                                        "showDownloadPermissionSlip",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "templates.showDownloadPermissionSlip",
                                  },
                                },
                                [
                                  _c("v-radio", {
                                    attrs: { label: "Yes", value: true },
                                  }),
                                  _c("v-radio", {
                                    attrs: { label: "No", value: false },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card",
                [
                  _c("v-card-title", [_vm._v("Permission Slip Keywords")]),
                  _c(
                    "v-card-text",
                    [
                      _c("small", [
                        _vm._v("Click on a row to copy the keyword"),
                      ]),
                      _c("v-simple-table", {
                        attrs: { dense: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _c("thead", [
                                  _c("tr", [
                                    _c("th", { staticClass: "text-left" }, [
                                      _vm._v("Value"),
                                    ]),
                                    _c("th", { staticClass: "text-left" }, [
                                      _vm._v("Keyword"),
                                    ]),
                                  ]),
                                ]),
                                _c(
                                  "tbody",
                                  [
                                    _vm._l(
                                      _vm.permissionSlipKeywords,
                                      function (item) {
                                        return [
                                          _c(
                                            "tr",
                                            {
                                              key: item.keyword,
                                              on: {
                                                click: function ($event) {
                                                  return _vm.copyToClipboard(
                                                    item.keyword
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("td", [
                                                _vm._v(_vm._s(item.value)),
                                              ]),
                                              _c("td", [
                                                _vm._v(_vm._s(item.keyword)),
                                              ]),
                                            ]
                                          ),
                                          item.subTags
                                            ? _vm._l(
                                                item.subTags,
                                                function (subItem) {
                                                  return _c(
                                                    "tr",
                                                    {
                                                      key: `${item.keyword}-${subItem.keyword}`,
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.copyToClipboard(
                                                            subItem.keyword
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass: "pl-16",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              subItem.value
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass: "pl-16",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              subItem.keyword
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              )
                                            : _vm._e(),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }